@import url(https://fonts.googleapis.com/css2?family=Sour+Gummy:ital,wght@0,100..900;1,100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&family=Montserrat:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* General styling for the navbar */
.navbar {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Aligns the content to the start */
  padding: 10px 20px;
  background-color: #00446B;
  color: white;
  position: relative;
  z-index: 100;
}

.logo img{
  margin-right: auto; /* Pushes the logo to the left */
  height: 40px; /* Adjust as needed */
}
.logo a{
  cursor: pointer;
}

.hamburger {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

/* Nav links */
.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 5dvw;
  gap: 5dvw;
  margin : 0;
  transition: height 0.5s ease, opacity 0.5s ease-in-out;
  width: 100%;
}

.nav-links li {
  position: relative;
  display: flex;
  opacity: 1; /* Ensure visibility on desktop */
  transition: opacity 0.5s ease, transform 0.5s ease; /* Keep animations */
  margin-top: 5px;
}

.nav-links a {
  text-decoration: none;
  color: white;
  font-size: 18px;
  transition: color 0.3s;
  flex-grow: 1; /* Ensure the link expands to fill the space */
}

.nav-links a:hover {
  color: #EE7057; /* Change hover color as needed */
}

/* Underline effect for desktop only */
.nav-links a::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background-color: #EE7057; /* Color of the line */
  transition: width 0.3s ease-in-out;
  margin: 5px auto; /* Center the line */
}

/* Show line animation only on larger screens */
@media (min-width: 769px) {
  .nav-links a:hover::after {
    width: 100%; /* Expand to full width on hover */
  }

  /* Hide chevron icon on desktop */
  .chevron-icon {
    display: none;
  }
}

/* Chevron icon */
.chevron-icon {
  opacity: 0;
  transition: opacity 0.3s ease;
  display: inline-flex; /* Keep icon inline */
  margin-left: 40dvw;
}

/* Show chevron icon only when the menu is open */
@media (max-width: 768px) {
  .nav-links.open li .chevron-icon {
    opacity: 0; /* Initially hidden */
  }

  .nav-links.open li:hover .chevron-icon {
    opacity: 1; /* Show on hover */
  }

  /* Ensure underline is not shown when menu is toggled */
  .nav-links.open a::after {
    width: 0; /* Hide underline */
  }
}

/* Responsive styling for mobile and tablet */
@media (max-width: 768px) {
  .navbar {
    justify-content: space-between; /* Align logo and hamburger to the sides */
  }

  .hamburger {
    display: block;
  }

  .nav-links {
    position: absolute;
    top: 100%; /* Aligns the menu right below the navbar */
    left: 0;
    background-color: #00446B;
    width: 100%;
    height: 0; /* Start with height 0 */
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    z-index: 99; /* Ensure it overlaps other content */
    opacity: 0; /* Initially hidden */
    overflow: hidden; /* Prevent content from spilling out */
    transition: height 0.5s ease, opacity 0.5s ease-in-out; /* Maintain transition */
  }

  .nav-links.open {
    height: auto; /* Allow it to expand fully */
    padding: 2vw 0; /* Add padding */
    opacity: 1; /* Make visible */
  }

  .nav-links li {
    display: flex;
    justify-content: space-between; /* Space text and chevron icon */
    text-align: left; /* Align text to the left */
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease; /* Keep animations */
    width: 90vw; /* Adjust width as needed */
  }

  /* Apply staggered animation delays */
  .nav-links.open li:nth-child(1) {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.25s;
  }
  .nav-links.open li:nth-child(2) {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.5s;
  }
  .nav-links.open li:nth-child(3) {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.75s;
  }
  .nav-links.open li:nth-child(4) {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 1s;
  }
}

/* Ensures the navbar resets correctly on window resize */
@media (min-width: 769px) {
  .nav-links {
    position: static; /* Reset position for desktop */
    opacity: 1; /* Ensure it's visible */
    height: auto; /* Ensure full height */
    flex-direction: row; /* Row layout for desktop */
    transition: opacity 0.5s ease; /* Allow opacity transition */
  }

  .nav-links.open {
    height: auto; /* Prevent height issues */
  }

  .nav-links li {
    opacity: 1; /* Ensure li are visible on desktop */
    transform: translateY(0); /* Reset transform on desktop */
  }
}

.home-section {
    width: 100dvw;
    height: 100vh;
    background: url(/static/media/HomeBackground.681c8bc3.jpeg) center/cover no-repeat;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay {
    filter: contrast(1.2); 
    background-color: #00446Bee;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.title {
    font-size: clamp(1.5rem, 4vw, 2rem); /* Adjusts between 1.5rem and 2rem based on viewport width */
    font-weight: 700;
    margin-top: 10%;
}

.overlay .logo {
    width: 30vh;
    margin: 10vh;
}

.subtitle {
    font-family: 'Montserrat', sans-serif; 
    font-size: clamp(1rem, 3vw, 1.5rem); /* Adjusts between 1rem and 1.5rem based on viewport width */
    margin-top: 15vh; 
}

.highlight {
    color: #EE7057;
}

/* General Section Styling */
.contact-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    background-color: #f9f9f9;
}

.contact-container {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    width: 100%;
    max-width: 1200px;
    justify-content: space-between;
    align-items: center;
}

.contact-details {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    flex: 1 1;
    align-items: flex-start;
    width: 100%;
    max-width: 400px;
}

.contact-card {
    display: flex;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
    background-color: #fff;
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.contact-icon {
    font-size: 1.5rem;
    color: #EE7057;
}

.contact-card h4 {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
}

.contact-card p {
    font-size: 0.9rem;
    margin: 5px 0 0;
    color: #555;
}

/* Form Section Styling */
.form-section {
    width: 100%;
    max-width: 600px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.form-description {
    margin: 10px 0 20px;
    color: #555;
    font-size: 0.9rem;
    line-height: 1.5;
}

.contact-form {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    width: 100%;
    margin-top: 20px;
}

.form-row {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    flex-wrap: wrap; /* Ensures wrapping on smaller screens */
    justify-content: space-between; /* Adds spacing between inputs */
}

.input-with-icon {
    position: relative;
    flex: 1 1; /* Allows inputs to grow and align evenly */
}

.input-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #aaa;
    font-size: 1rem;
    pointer-events: none; /* Prevent icon from blocking input interactions */
}

input, textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
}

input {
    padding-right: 40px; /* Space for the right icon */
}

textarea {
    resize: vertical;
    min-height: 120px;
    padding-right: 10px;
}

select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
    -webkit-appearance: none;
            appearance: none; /* Removes default dropdown arrow for consistent styling */
    padding-right: 40px; /* Space for the icon */
    cursor: pointer;
}

select:focus {
    border-color: #00446B;
    outline: none;
}

.input-with-icon select {
    padding-right: 40px; /* Space for the right-aligned icon */
}

.input-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #aaa;
    font-size: 1rem;
    pointer-events: none;
}

.submit-button {
    background-color: #EE7057;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
    width: 100%;
}

.submit-button:hover {
    background-color: #00446B;
}

/* Map Section Styling */
.map-section {
    text-align: center;
    width: 100%;
    max-width: 800px;
    margin-top: 50px;
}

.map-section h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.map-section p {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 20px;
}

.map-container {
    width: 100%;
    height: 300px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Responsive Styles */
@media (min-width: 768px) {
    .contact-container {
        flex-direction: row;
        grid-gap: 40px;
        gap: 40px;
    }

    .contact-details {
        max-width: 400px;
    }

    .form-section {
        max-width: 600px;
    }

    .form-row {
        flex-direction: row;
    }

    .form-group input {
        flex: 1 1;
    }

    textarea {
        flex: 1 1 100%;
    }

    .submit-button {
        max-width: 300px;
        margin: 0 auto;
    }
}

@media (min-width: 1024px) {
    .contact-section {
        padding: 60px 40px;
    }

    .map-section {
        margin-top: 40px;
    }
}

.footer {
    width: 100dvw;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #00446B;
    color: white;
}

.footer-top {
    display: flex;
    justify-content: space-between;
    height: 75%; /* Adjusted to fit the new 50vh */
}

.footer-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end; /* Adjusted to space elements evenly */
}

.footer-menu {
    display: flex;
    justify-content: space-evenly; /* Spacing links evenly horizontally */
    width: 100%; /* Ensures it spans the width of the parent */
}

.menu-items {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    grid-gap: 20px;
    gap: 20px; /* Adjust this as needed for more spacing */
}

.menu-items li a {
    text-decoration: none;
    color: white;
    font-size: clamp(0.8rem, 3vw, 1rem); 
    border-radius: 5px; /* Gives the links a subtle rounded corner */
    transition: background-color 0.3s, color 0.3s; /* Smooth hover effect */
}

.menu-items a:hover {
    color: #EE7057; /* Change hover color as needed */
  }
  
  /* Underline effect for desktop only */
  .menu-items a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background-color: #EE7057; /* Color of the line */
    transition: width 0.3s ease-in-out;
    margin: 5px auto; /* Center the line */
  }

  .menu-items a:hover::after {
    width: 100%; /* Expand to full width on hover */
  }


.footer-socials {
    display: flex;
    justify-content: center; /* Aligns social icons at the bottom */
    align-items: flex-end;
    grid-gap: 15px;
    gap: 15px; /* Adds space between social icons */
    width: 100%; /* Ensures it spans the width of the parent */
    margin: 2% 0; /* Adjusts space around the social icons */
}

.social-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%; /* Rounds the divs */
    background-color: #ffffff20; /* Semi-transparent white */
    color: white;
    transition: background-color 0.3s, color 0.3s; /* Smooth hover effect */
}

.social-icon:hover {
    background-color: #EE7057; /* Changes background on hover */
    color: #00446B; /* Changes icon color on hover */
}

.horizontal-separator {
    height: 2px;
    background-color: #EE7057;
    margin: 0 2dvw; /* Aligns with the footer padding */
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.menu-items {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
}

.footer-socials {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
}

.social-icon {
    font-size: 1.5rem;
}

.footer-bottom {
    text-align: center;
    height: 20%; /* Adjusted to fit the new 50vh */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp(0.5rem, 3vw, 1rem); 
    margin: 0 5%; /* Adjusts space around the text */
}

#myLogo{
    font-weight: 600;
    font-family: "Sour Gummy", sans-serif;
    font-size: 1.3rem;
}
/* Importing Raleway and a complementary font */

.App {
  font-family: 'Raleway', sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  flex-direction: column;
}

html {
  scroll-behavior: smooth;
}
