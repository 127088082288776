/* General Section Styling */
.contact-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    background-color: #f9f9f9;
}

.contact-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 1200px;
    justify-content: space-between;
    align-items: center;
}

.contact-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
    align-items: flex-start;
    width: 100%;
    max-width: 400px;
}

.contact-card {
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: #fff;
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.contact-icon {
    font-size: 1.5rem;
    color: #EE7057;
}

.contact-card h4 {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
}

.contact-card p {
    font-size: 0.9rem;
    margin: 5px 0 0;
    color: #555;
}

/* Form Section Styling */
.form-section {
    width: 100%;
    max-width: 600px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.form-description {
    margin: 10px 0 20px;
    color: #555;
    font-size: 0.9rem;
    line-height: 1.5;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    margin-top: 20px;
}

.form-row {
    display: flex;
    gap: 10px;
    flex-wrap: wrap; /* Ensures wrapping on smaller screens */
    justify-content: space-between; /* Adds spacing between inputs */
}

.input-with-icon {
    position: relative;
    flex: 1; /* Allows inputs to grow and align evenly */
}

.input-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #aaa;
    font-size: 1rem;
    pointer-events: none; /* Prevent icon from blocking input interactions */
}

input, textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
}

input {
    padding-right: 40px; /* Space for the right icon */
}

textarea {
    resize: vertical;
    min-height: 120px;
    padding-right: 10px;
}

select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
    appearance: none; /* Removes default dropdown arrow for consistent styling */
    padding-right: 40px; /* Space for the icon */
    cursor: pointer;
}

select:focus {
    border-color: #00446B;
    outline: none;
}

.input-with-icon select {
    padding-right: 40px; /* Space for the right-aligned icon */
}

.input-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #aaa;
    font-size: 1rem;
    pointer-events: none;
}

.submit-button {
    background-color: #EE7057;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
    width: 100%;
}

.submit-button:hover {
    background-color: #00446B;
}

/* Map Section Styling */
.map-section {
    text-align: center;
    width: 100%;
    max-width: 800px;
    margin-top: 50px;
}

.map-section h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.map-section p {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 20px;
}

.map-container {
    width: 100%;
    height: 300px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Responsive Styles */
@media (min-width: 768px) {
    .contact-container {
        flex-direction: row;
        gap: 40px;
    }

    .contact-details {
        max-width: 400px;
    }

    .form-section {
        max-width: 600px;
    }

    .form-row {
        flex-direction: row;
    }

    .form-group input {
        flex: 1;
    }

    textarea {
        flex: 1 1 100%;
    }

    .submit-button {
        max-width: 300px;
        margin: 0 auto;
    }
}

@media (min-width: 1024px) {
    .contact-section {
        padding: 60px 40px;
    }

    .map-section {
        margin-top: 40px;
    }
}
