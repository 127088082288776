@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&family=Montserrat:wght@400;700&display=swap'); /* Importing Raleway and a complementary font */

.App {
  font-family: 'Raleway', sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  flex-direction: column;
}

html {
  scroll-behavior: smooth;
}